/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import { Space, Animated } from '../../../Widgets';
import * as Styles from '../../../pages/Styles/about.module.scss';
import { Col, Row } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';

interface IAboutEN {}

const AboutEN: React.FC<IAboutEN> = () => {
    return (
        <>
            <Row>
                <Col md={12} className="text-center">
                    <p>
                        BONMEA is not simply about food – it’s about passion for
                        food!
                    </p>
                    <p>
                        We are a sourcing company focused on providing services
                        to retail and industry customers around Europe.
                    </p>
                    <p>
                        We are a sustainable, fully integrated producer,
                        exporter and importer of frozen sea food.
                    </p>
                    <p>
                        We commit ourselves every day to bring YOU just the
                        finest foods from around the world.
                    </p>
                </Col>
            </Row>

            <Space height={32} />

            <Row>
                <Col md={8} xs={12} className="offset-md-2">
                    <StaticImage
                        src={'../../../Resources/Images/About/about_1.jpeg'}
                        style={{
                            objectFit: 'cover',
                        }}
                        className={Styles.image}
                        alt=""
                    />
                </Col>
            </Row>

            <Space height={72} />

            <Row>
                <Col md={12} className="text-center">
                    <h3>Our philosophy is simple and effective:</h3>

                    <p>We love food - We love nature - We love people</p>

                    <p>
                        And that is what the name BONMEA is about: the best food
                        – not just for yourself but also FOR YOUR LOVED ONES.
                    </p>

                    <p>
                        We invest heavily in food safety and sustainability
                        certifications.
                    </p>

                    <p>
                        Our company only works together with trusted farms that
                        have a nature based feed, proper waste management
                        practices and minimized water exchange.
                    </p>
                </Col>
            </Row>

            <Space height={72} />

            <Row>
                <Col md={8} xs={12} className="offset-md-2">
                    <StaticImage
                        src={'../../../Resources/Images/About/about_2.jpeg'}
                        style={{
                            objectFit: 'cover',
                        }}
                        className={Styles.image}
                        alt=""
                    />
                </Col>
            </Row>

            <Space height={72} />

            <Row>
                <Col md={12} className="text-center">
                    <p>
                        We allow time between production cycles for nature to
                        restore itself and to protect the coastal land and water
                        resources.
                    </p>

                    <p>
                        We constantly work hard to find new ways to preserve our
                        ecosystem and reduce our footprint and make sure such
                        practices are implemented in the ever growing
                        aquaculture industry.
                    </p>

                    <p>
                        We adhere to global standards and offer our customers a
                        wide range of the world’s finest foods, be it ASC and
                        MSC or organic.
                    </p>

                    <p>BONMEA is simply SUSTAINALICIOUS.</p>
                </Col>
            </Row>

            <Space height={72} />

            <Row>
                <Col md={8} xs={12} className="offset-md-2">
                    <StaticImage
                        src={'../../../Resources/Images/About/about_3.jpeg'}
                        style={{
                            objectFit: 'cover',
                        }}
                        className={Styles.image}
                        alt=""
                    />
                </Col>
            </Row>
        </>
    );
};

export default AboutEN;
