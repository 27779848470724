/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import { Space } from '../../../Widgets';
import * as Styles from '../../../pages/Styles/about.module.scss';
import { Col, Row } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';

interface IAboutEN {}

const AboutEN: React.FC<IAboutEN> = () => {
    return (
        <>
            <Row>
                <Col md={12} className="text-center">
                    <p>
                        Bei BONMEA geht es nicht nur um Essen - es geht um die
                        Leidenschaft für Essen!
                    </p>
                    <p>
                        Wir sind ein Beschaffungsunternehmen, das sich auf die
                        Bereitstellung von Dienstleistungen für Kunden aus
                        Großhandel und Großrestaurants in ganz Europa
                        konzentriert.
                    </p>
                    <p>
                        Wir sind ein nachhaltiger, voll integrierter Produzent,
                        Exporteur und Importeur von gefrorenen Meeresfrüchten.
                    </p>
                    <p>
                        Es ist unsere tägliche Aufgabe, DIR nur die besten
                        Lebensmittel aus aller Welt zu besorgen.
                    </p>
                </Col>
            </Row>

            <Space height={32} />

            <Row>
                <Col md={8} xs={12} className="offset-md-2">
                    <StaticImage
                        src={'../../../Resources/Images/About/about_1.jpeg'}
                        style={{
                            objectFit: 'cover',
                        }}
                        className={Styles.image}
                        alt=""
                    />
                </Col>
            </Row>

            <Space height={72} />

            <Row>
                <Col md={12} className="text-center">
                    <h3>Unsere Philosophie ist einfach und effektiv:</h3>

                    <p>
                        Wir lieben Essen - Wir lieben die Natur - Wir lieben
                        Menschen
                    </p>

                    <p>
                        Und darum geht es beim Namen BONMEA: das beste Essen –
                        nicht nur für Dich selbst, sondern auch für Deine
                        Liebsten.
                    </p>

                    <p>
                        Wir verkaufen Dir nur - was wir auch unseren eigenen
                        Kindern geben würden.
                    </p>

                    <p>
                        Wir nutzen die Natur nicht aus, sondern wir sind Teil
                        der Natur.
                    </p>

                    <p>
                        Daher investieren stark in Zertifizierungen für
                        Lebensmittelsicherheit und Nachhaltigkeit. Unser
                        Unternehmen arbeitet nur mit vertrauenswürdigen
                        Betrieben zusammen, die wir persönlich kennen und die
                        ein naturnahes Futter, eine ordnungsgemäße
                        Abfallentsorgung und einen minimalen Wasseraustausch
                        haben.
                    </p>
                </Col>
            </Row>

            <Space height={72} />

            <Row>
                <Col md={8} xs={12} className="offset-md-2">
                    <StaticImage
                        src={'../../../Resources/Images/About/about_2.jpeg'}
                        style={{
                            objectFit: 'cover',
                        }}
                        className={Styles.image}
                        alt=""
                    />
                </Col>
            </Row>

            <Space height={72} />

            <Row>
                <Col md={12} className="text-center">
                    <p>
                        Wir lassen der Natur zwischen den Produktionszyklen
                        Zeit, um sich zu erholen und die Land- und
                        Wasserressourcen der Küste zu schützen.
                    </p>

                    <p>
                        Wir arbeiten ständig daran, neue Wege zu finden, um
                        unser Ökosystem zu erhalten und unseren ökologischen
                        Fußabdruck zu reduzieren, um einen Beitrag dafür zu
                        leisten, dass solche Praktiken auch in anderen
                        Aquakulturindustrien umgesetzt werden.
                    </p>

                    <p>
                        Wir halten uns nicht nur an globale Standards sondern
                        versuchen besser zu sein als diese und bieten unseren
                        Kunden damit eine große Auswahl der besten Lebensmitteln
                        der Welt, seien sie ASC, MSC oder Bio-zertifiziert.
                    </p>

                    <p>BONMEA: gut für mich - gut für Dich!</p>
                </Col>
            </Row>

            <Space height={72} />

            <Row>
                <Col md={8} xs={12} className="offset-md-2">
                    <StaticImage
                        src={'../../../Resources/Images/About/about_3.jpeg'}
                        style={{
                            objectFit: 'cover',
                        }}
                        className={Styles.image}
                        alt=""
                    />
                </Col>
            </Row>
        </>
    );
};

export default AboutEN;
