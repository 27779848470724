/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { useIntl } from 'gatsby-plugin-intl';
import { PageHeader, Space } from '../Widgets';
import { Container, Row, Col } from 'react-bootstrap';
import classnames from 'classnames';
import AboutEN from '../components/Content/About/AboutEN';
import AboutDE from '../components/Content/About/AboutDE';
import * as Styles from './Styles/about.module.scss';

interface IAboutPage {}

const AboutPage: React.FC<IAboutPage> = () => {
    const intl = useIntl();

    return (
        <Layout>
            <SEO
                title={intl.formatMessage({ id: 'about.pageTitle' })}
                description={intl.formatMessage({
                    id: 'about.metaDescription',
                })}
                lang={intl.locale}
            />

            <PageHeader
                title={intl.formatMessage({
                    id: 'about.bannerLabel',
                })}
            />

            <Container className={Styles.content}>
                <Row>
                    <Col
                        md={12}
                        className={classnames('pageContent', 'text-center')}>
                        <h1>{intl.formatMessage({ id: 'about.title' })}</h1>
                    </Col>
                </Row>

                {intl.locale === 'en' ? <AboutEN /> : <AboutDE />}
            </Container>

            <Space height={40} />
        </Layout>
    );
};

export default AboutPage;
